import moment from "moment";
import { useMemo } from "react";
import { Badge, Col, Container, Nav, Navbar, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { get_config_vars } from "../common/serverconfig";
import { f_num } from "../common/utility";

export function NavBar(props) {
  let query = useQuery("config_vars", get_config_vars, {
    onSuccess: (d) => {
      if (window.location.hostname.toLowerCase() === "localhost") {
        d.ENABLE_EB = "1";
      }
    },
  });
  return (
    <Navbar fixed="top" variant="dark" bg="dark" data-bs-theme="dark" className="no-print">
      <Container>
        <Navbar.Brand>
          {query && query.data && query.data.COMPANY_ICONURL && <img src={query.data.COMPANY_ICONURL} height="30" className="d-inline-block align-top" alt="" />}
          <h5 className="fw-bold text-light d-inline ms-2">{(query && query.data && query.data.COMPANY_NAME) ?? "Solar"}</h5>
        </Navbar.Brand>
        <Nav variant="pills" className="text-light no-print">
          <Nav.Item>
            <Nav.Link href="/day">Day</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/month">Month</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/report/summary">Summary</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/report/calendar">Calendar</Nav.Link>
          </Nav.Item>
          {query && query.data && query.data.ENABLE_EB && (
            <Nav.Item>
              <Nav.Link href="/eb">EB</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export function GridBoxView(props) {
  return (
    <Row className="mx-auto w-100">
      {props.data.map((h, i) => {
        let k = typeof props.keyfield === "function" ? props.keyfield(h, i) : null;
        let v = typeof props.valuefield === "function" ? props.valuefield(h, i) : null;
        return <GridBoxViewColumn i={i} k={k} v={v} />;
      })}
    </Row>
  );
}

export function GridBoxViewColumn(props) {
  let value = useMemo(() => {
    if (props.v) {
      if (typeof props.v === "number") return Math.round(props.v);
      else return props.v;
    }
    return "-";
  }, [props.v]);

  return (
    <Col key={`GridBoxView_${props.i}`} className="col-md-2 border border-light shadow p-2 m-2 rounded cursor-pointer" style={{ opacity: typeof props.v === "number" ? (props.v > 0 ? 1 : 0.3) : 1 }}>
      <div className="text-center">
        <div className="text-secondary">{props.k}</div>
        <h5 className="my-2 text-light">{value}</h5>
      </div>
    </Col>
  );
}

export const SpinnerView = (props) => <Spinner animation="border" variant="light" className="mx-auto d-flex my-5" />;

export function DateInputView(props) {
  let value = useMemo(() => {
    if (props.selected) {
      if (props.selected instanceof Date) {
        let m = new moment(props.selected);
        return m.format(props.type === "month" ? "yyyy-MM" : "YYYY-MM-DD");
      }
    }
    return props.selected;
  }, [props.selected, props.type]);

  return (
    <div className=" text-center d-flex flex-column mx-auto justify-content-center">
      {props.title && <h6 className="text-secondary text-center">{props.title}</h6>}
      <input
        className="dateinputview mx-auto"
        type={props.type ?? "date"}
        value={value}
        onChange={(e) => {
          let v = e.target.value;
          if (v) v = new Date(v);
          props.onChange && props.onChange(v);
        }}
      ></input>
    </div>
  );
}

export function LabelBadge(props) {
  return (
    <Badge bg="dark" className="d-flex justify-content-between">
      <div>{props.title}</div>
      {props.value && <div>{f_num(props.value)}</div>}
    </Badge>
  );
}

export function YearDropdown(props) {
  let years = useMemo(() => {
    let current = moment().year();
    let arr = [];
    for (var i = 0; i < 5; i++) {
      arr.push(current - i);
    }
    return arr;
  }, []);

  return (
    <select
      name="years"
      id="years_select"
      className="dateinputview mb-5"
      value={props.value}
      onChange={(e) => {
        let v = parseInt(e.target.value);
        props.onChange && props.onChange(v);
      }}
    >
      {years.map((y) => (
        <option value={y}>{y}</option>
      ))}
    </select>
  );
}

export function AboutView() {
  return (
    <Container fluid className="my-4 text-center text-secondary">
      <h6>
        ALC Solar Server
        <br />
        Copyright © {moment().year()}
        <br />
        ☀️ Aadhithya Alagarasan ☀️
        <br />
      </h6>
    </Container>
  );
}
