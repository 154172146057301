// export const servers = ["http://localhost:4000/"];

import { magixFETCH } from "./fetcher";

let heroku_url = `https://api.heroku.com/apps`;
let herku_options = {
  method: "get",
  headers: {
    Accept: "application/vnd.heroku+json; version=3",
    Authorization: "Bearer d55ff967-1076-4725-8e4d-f3c0e4ef0b8d",
  },
};

export const get_config_vars = () => {
  let config_vars = sessionStorage.getItem("config_vars");
  if (config_vars) return Promise.resolve(JSON.parse(config_vars));
  else {
    let host = get_host();
    if (!host) {
      config_vars = {};
      // sessionStorage.setItem("config_vars", JSON.stringify(config_vars));
      alert("Error : No Valid Host Found in URL");
      return Promise.resolve(config_vars);
    }

    return magixFETCH(`${heroku_url}/${host}/config-vars`, null, null, herku_options).then((res) => {
      config_vars = res;
      if (res) {
        if (res.SERVER_URL && typeof res.SERVER_URL === "string") {
          config_vars.SERVER_URL = res.SERVER_URL.trim().split(",");
        }
        if (res.COMPANY_NAME) {
          document.title = res.COMPANY_NAME;
        }
        sessionStorage.setItem("config_vars", JSON.stringify(config_vars));
      } else {
        config_vars = {};
        alert("Error : No Servers Configured in Config Vars Settings");
      }
      return config_vars;
    });
  }
};

const get_host = () => {
  let host = window.location.hostname;
  if (host.toLowerCase() === "localhost") {
    // return ["sreegokhulamsolar"];
    // return ["djsolar"];
    return ["alcsolarnewui"];
    return false;
  }
  if (host && host.split) {
    let host_ = host.split("-");
    if (host_.length > 0) {
      return host_[0];
    }
  }
  return false;
};
