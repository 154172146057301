import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { f_num } from "../common/utility";
import { QueryIEX } from "./DayReport";
import { Button, Container, Stack, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { allSettled, fetch_machines, fetch_summary } from "../common/fetcher";
import { DateInputView, SpinnerView } from "../components/CommonViews";
import moment from "moment";

export const DatePickerUI = forwardRef((props, _ref) => {
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();

  useImperativeHandle(_ref, () => ({
    startdate,
    setstartdate,
    enddate,
    setenddate,
  }));

  useEffect(() => {
    if (startdate) sessionStorage.setItem("startdate", startdate.toISOString());

    if (props.onChange_startdate) {
      props.onChange_startdate(startdate);
    }
  }, [props, startdate]);

  useEffect(() => {
    if (enddate) sessionStorage.setItem("enddate", enddate.toISOString());

    if (props.onChange_enddate) {
      props.onChange_enddate(enddate);
    }
  }, [enddate, props]);

  useEffect(() => {
    let s = sessionStorage.getItem("startdate");
    setstartdate(s ? moment(s) : moment().startOf("month"));

    let e = sessionStorage.getItem("enddate");
    setenddate(e ? moment(e) : moment());
  }, []);

  return (
    <Stack gap={3} direction="horizontal" className="mb-4 justify-content-center">
      <DateInputView title="From Date" selected={startdate && startdate.toDate()} onChange={(date) => setstartdate(moment(date))} />
      <DateInputView title="To Date" selected={enddate && enddate.toDate()} onChange={(date) => setenddate(moment(date).endOf("day"))} />
    </Stack>
  );
});

export function EBReport(props) {
  // let ctx = useContext(ReportContext);

  let dateRef = useRef();
  let [startdate, setstartdate] = useState(null);
  let [enddate, setenddate] = useState(null);

  let query_machines = useQuery("fetch_machines", () => fetch_machines());

  let query_summary = useQuery(
    ["fetch_summary", startdate, enddate],
    () => {
      let promises = query_machines.data.map((d) => {
        return fetch_summary(d.server, [d.data._id], startdate, enddate, true, null, null, null, null, false);
      });

      let promises_final = allSettled(promises).then((res) => {
        return res.map((r) => r.value);
      });

      return promises_final;
    },
    { enabled: typeof query_machines.data === "object" ? true : false && startdate !== null && enddate != null }
  );

  let query_iex = useQuery(
    ["fetch_iex_eb", query_machines.data, startdate, enddate],
    () => {
      let mcs = query_machines.data.map((d) => d.data._id);
      return QueryIEX(query_machines.data, mcs, startdate, enddate, false);
    },
    { enabled: Array.isArray(query_machines.data) }
  );

  let table_data = useMemo(() => {
    if (query_summary.data && query_iex.data) {
      let mcs = query_machines.data;
      query_summary.data.forEach((arr, i) => {
        let sum = { peak: 0, nonpeak: 0, total: 0, maxdemand: 0 };
        arr &&
          arr.forEach((d) => {
            let is_gen = mcs[i].data.type === "gen";
            let d0 = d[0];
            if (d0) {
              if (is_gen) {
                sum["total"] += d0.peaks["total_yield"];
                sum["peak"] += d0.peaks["mpeak_yield"] + d0.peaks["epeak_yield"];
                sum["nonpeak"] += d0.peaks["mnonpeak_yield"] + d0.peaks["enonpeak_yield"];
              } else {
                sum["total"] += d0.peaks["total"];
                sum["peak"] += d0.peaks["mpeak"] + d0.peaks["epeak"];
                sum["nonpeak"] += d0.peaks["mnonpeak"] + d0.peaks["enonpeak"];
              }

              if (d0.peaks.maxdemand && typeof d0.peaks.maxdemand === "object" && d0.peaks.maxdemand.data && d0.peaks.maxdemand.data.power) {
                if (d0.peaks.maxdemand.data.power > sum.maxdemand) {
                  sum.maxdemand = d0.peaks.maxdemand.data.power;
                }
              }
            }
          });
        mcs[i].sum = sum;
      });

      query_iex.data.forEach((arr, i) => {
        if (arr) {
          let sum_iex = { peak: 0, nonpeak: 0, total: 0 };
          arr.forEach((d) => {
            if (d) {
              sum_iex["peak"] += d["mpeak"] + d["epeak"];
              sum_iex["nonpeak"] += d["mnonpeak"] + d["enonpeak"];
              sum_iex["total"] += d["mpeak"] + d["epeak"] + d["mnonpeak"] + d["enonpeak"];
            }
          });
          mcs[i].sum_iex = sum_iex;
        }
      });

      //Seperate GEN & CONS
      let gens = [];
      let cons = [];

      mcs.forEach((m) => {
        if (m.data.type === "gen") {
          gens.push(m);
        } else {
          cons.push(m);
        }
      });

      return { gens, cons };
    }
    return null;
  }, [query_machines.data, query_summary.data, query_iex.data]);

  let share_settings = useMemo(() => {
    let d = {
      "650a8c4ff185b1cd5e0f1960": {
        //Fac Consumption 1
        "650ff586f49aafcb2eab5c8a": 0.55, //6MW
        "650ff710f49aafcb2eab5c8e": true, //10MW - Balance
      },
      "650a8cd5f185b1cd5e0f1963": {
        // Fac Consumption 2
        "650ff586f49aafcb2eab5c8a": 0.45, //6MW
        "650ff710f49aafcb2eab5c8e": true, //10MW - Balance
      },
      "654ef6da818ad258564b142e": {
        // Spinning Consumption
        "650ff586f49aafcb2eab5c8a": true, //6MW - Balance
        "650ff710f49aafcb2eab5c8e": true, //10MW - Balance
      },
    };
    return d;
  }, []);

  let share_settings_primary_gen = useMemo(() => {
    let d = {
      "650a8c4ff185b1cd5e0f1960": ["650ff710f49aafcb2eab5c8e"], //FAC1 : 10MW
      "650a8cd5f185b1cd5e0f1963": ["650ff586f49aafcb2eab5c8a"], //FAC2 : 6MW
    };
    return d;
  }, []);

  let ui_table_data1 = useMemo(() => {
    if (table_data) {
      return (
        <>
          <div className="mt-5 text-white p-3">Summary</div>
          <Table size="sm" bordered>
            <thead>
              <tr>
                <td>Name</td>
                <td>Peak</td>
                <td>NonPeak</td>
                <td>Total</td>
                <td>IEX Total</td>
                <td>IEX Peak</td>
                <td>IEX NonPeak</td>
              </tr>
            </thead>
            <tbody>
              {table_data.cons.map((t) => {
                return (
                  <tr>
                    <td>{t.data.name}</td>
                    <td>{f_num(t.sum.peak)}</td>
                    <td>{f_num(t.sum.nonpeak)}</td>
                    <td>{f_num(t.sum.total)}</td>
                    <td>{f_num(t.sum_iex && t.sum_iex.total)}</td>
                    <td>{f_num(t.sum_iex && t.sum_iex.peak)}</td>
                    <td>{f_num(t.sum_iex && t.sum_iex.nonpeak)}</td>
                  </tr>
                );
              })}
              {table_data.gens.map((t) => {
                return (
                  <tr>
                    <td>{t.data.name}</td>
                    <td>{f_num(t.sum.peak)}</td>
                    <td>{f_num(t.sum.nonpeak)}</td>
                    <td>{f_num(t.sum.total)}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      );
    }
  }, [table_data]);

  let share_data = useMemo(() => {
    if (table_data && share_settings) {
      let cons = [...table_data.cons];
      let gens = [...table_data.gens];

      cons.forEach((c, i) => {
        if (!c.sum_solar) {
          cons[i].sum_solar = {};
        }

        //Add gen mc to con object
        cons[i].share_gens = [];

        if (share_settings[c.data._id]) {
          gens.forEach((g, j) => {
            if (!g.sum_balance) {
              gens[j].sum_balance = { ...g.sum };
            }

            let primary_gen = share_settings_primary_gen[c.data._id];
            if (primary_gen && primary_gen.indexOf(g.data._id) > -1) {
              cons[i].share_gens.push(g);
            }

            let share = null;
            share = share_settings[c.data._id][g.data._id];

            if (share) {
              let keys = ["peak", "nonpeak", "total"];
              keys.forEach((k) => {
                if (typeof share === "number") {
                  let s = g.sum[k] * share;
                  cons[i].sum_solar[k] = (cons[i].sum_solar[k] ?? 0) + s;
                  gens[j].sum_balance[k] = gens[j].sum_balance[k] - s;
                } else if (typeof share === "boolean" && share === true) {
                  let b = cons[i].sum[k] - cons[i].sum_iex[k] - (cons[i].sum_solar[k] ?? 0);
                  let avai = Math.min(gens[j].sum_balance[k], b);
                  if (!cons[i].sum_solar[k]) {
                    cons[i].sum_solar[k] = 0;
                  }
                  cons[i].sum_solar[k] += avai;
                  gens[j].sum_balance[k] = gens[j].sum_balance[k] - avai;
                }
              });
            }
          });
        }

        cons[i].sum_net = {
          peak: c.sum.peak - ((c.sum_iex && c.sum_iex.peak) ?? 0) - ((c.sum_solar && c.sum_solar.peak) ?? 0),
          nonpeak: c.sum.nonpeak - ((c.sum_iex && c.sum_iex.nonpeak) ?? 0) - ((c.sum_solar && c.sum_solar.nonpeak) ?? 0),
          total: c.sum.total - ((c.sum_iex && c.sum_iex.total) ?? 0) - ((c.sum_solar && c.sum_solar.total) ?? 0),
        };
      });

      return { gens, cons };
    }
  }, [table_data, share_settings, share_settings_primary_gen]);

  let ui_service_data = useMemo(() => {
    if (share_data) {
      return share_data.cons.map((c) => {
        return (
          <>
            <div className="mt-5 text-white p-3">
              {c.data.name}
              <Link to={"detail"} state={{ data: c, startdt: startdate && startdate.toDate(), enddt: enddate && enddate.toDate() }}>
                <Button size="sm" className="ms-2" variant="light">
                  View Details
                </Button>
              </Link>
            </div>
            <Table className="text-end" size="sm" bordered>
              <thead>
                <tr className="text-start fw-bold">
                  <td className="text-center">Details</td>
                  <td className="text-center">Total (C24)</td>
                  <td className="text-center">Peak (C1 + C2)</td>
                  <td className="text-center">Non Peak (C4 + C5)</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-start">Consumption Summed</td>
                  <td>{f_num(c.sum.total)}</td>
                  <td>{f_num(c.sum.peak)}</td>
                  <td>{f_num(c.sum.nonpeak)}</td>
                </tr>
                <tr>
                  <td className="text-start">IEX</td>
                  <td>{f_num(c.sum_iex && c.sum_iex.total)}</td>
                  <td>{f_num(c.sum_iex && c.sum_iex.peak)}</td>
                  <td>{f_num(c.sum_iex && c.sum_iex.nonpeak)}</td>
                </tr>

                <tr>
                  <td className="text-start">Solar</td>
                  <td>{f_num(c.sum_solar && c.sum_solar.total)}</td>
                  <td>{f_num(c.sum_solar && c.sum_solar.peak)}</td>
                  <td>{f_num(c.sum_solar && c.sum_solar.nonpeak)}</td>
                </tr>
                <tr className="fw-bold">
                  <td className="text-start">Net Industrial Consumption</td>
                  <td>{f_num(c.sum_net && c.sum_net.total)}</td>
                  <td>{f_num(c.sum_net && c.sum_net.peak)}</td>
                  <td>{f_num(c.sum_net && c.sum_net.nonpeak)}</td>
                </tr>
              </tbody>
            </Table>
          </>
        );
      });
    }
  }, [share_data]);

  return (
    <>
      <DatePickerUI ref={dateRef} onChange_startdate={(date) => setstartdate(date)} onChange_enddate={(date) => setenddate(date)} />

      {query_summary.isLoading || query_iex.isLoading ? (
        <SpinnerView />
      ) : query_summary.isError || query_iex.isError ? (
        <>
          {query_summary.error}
          {query_iex.error}
        </>
      ) : (
        <>
          {ui_table_data1}
          {ui_service_data}
        </>
      )}
    </>
  );
}

export function EBDetailReport(props) {
  let { state } = useLocation();

  let d = state && state.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (!d) {
      return navigate(`/eb`, { replace: true });
    }
  }, [d, navigate]);

  const calc = useCallback(
    (no_gen) => {
      if (!d) return;

      if (typeof d.sum_iex !== "object") {
        d.sum_iex = { total: 0, mpeak: 0, epeak: 0 };
      }

      let m_gen_meter_count = 0;
      let m_con_meter_count = 1;
      let demand_sanctioned = d.data.mw * 1000;
      let gen_total_mw = 0;

      if (Array.isArray(d.share_gens)) {
        m_gen_meter_count = d.share_gens.length ?? 0;
        gen_total_mw = d.share_gens.reduce((p, v, i) => v.data.mw + p, 0) ?? 0;
      }

      if (!m_con_meter_count) {
        alert("ERROR : m_meter_count is 0 !");
        return;
      } else if (!demand_sanctioned) {
        alert("ERROR : demand_sanctioned is 0 !");
        return;
      }

      let demand_max = d.sum.maxdemand;
      let demand_charged = Math.max(demand_sanctioned * 0.9, demand_max);
      let m_demand_rate = 589;
      let demand_total = demand_charged * m_demand_rate;
      let tneb_peaktotal = d.sum_net.peak; //d.sum.total - d.sum_solar.total - d.sum_iex.total;
      let tneb_nonpeaktotal = Math.max(0, d.sum_net.nonpeak); //Math.max(0, d.sum.nonpeak - d.sum_solar.nonpeak - d.sum_iex.nonpeak);
      let tneb_total = d.sum_net.total; //tneb_peaktotal + tneb_nonpeaktotal;

      let m_industrial_con = 7.25;
      let industrial_con = tneb_total * m_industrial_con;

      let m_peakhour_con = 1.8125;
      let peakhour_con = tneb_peaktotal * m_peakhour_con;

      let m_nonpeakhour_con = 0.3625;
      let nonpeakhour_con = -(tneb_nonpeaktotal * m_nonpeakhour_con);

      let total_energycharges = industrial_con + peakhour_con + nonpeakhour_con;

      let total_energycharges_demand = total_energycharges + demand_total;

      let m_meter_rent = 4678.7;

      let meter_rent = m_meter_rent * m_con_meter_count;

      let m_cross_subsidy_charges = 2.2656;
      let cross_subsidy_charges = m_cross_subsidy_charges * d.sum_iex.total;

      let m_add_surcharge = 0.3422;
      let add_surcharge = m_add_surcharge * d.sum_iex.total;

      let m_electricity_tax_perc = 5;
      let electricity_tax = (industrial_con + peakhour_con) * (m_electricity_tax_perc / 100);

      let assesment_amount = total_energycharges_demand + meter_rent + cross_subsidy_charges + add_surcharge + electricity_tax;

      let m_self_gen_tax_perc = 10;
      let self_gen_tax = (d.sum_solar.total ?? 0) * (m_self_gen_tax_perc / 100);

      let m_iex_etax_perc = 10;
      let iex_etax = d.sum_iex.total * (m_iex_etax_perc / 100);

      let noofdays = 31; //AAA - Need to FIX//ctx.enddate.diff(ctx.startdate, "days") + 1;
      let m_amr_meter_charges = 430;
      let amr_meter_charges = m_gen_meter_count * m_amr_meter_charges;

      let m_transmisson_charges = no_gen ? 0 : 2790.134;
      let transmission_charges = m_transmisson_charges * gen_total_mw * noofdays;

      let m_system_operation_charges = no_gen ? 0 : 46.231;
      let system_operation_charges = m_system_operation_charges * gen_total_mw * noofdays;

      let m_rkvar_penalty = 1250;
      let rkvar_penalty = m_rkvar_penalty * gen_total_mw;

      let m_import_energy_charges = no_gen ? 0 : 18000;
      let import_energy_charges = m_import_energy_charges * m_gen_meter_count;

      let m_scheduling_charges = no_gen ? 0 : 100;
      let scheduling_charges = m_scheduling_charges * noofdays * m_gen_meter_count;

      let oa_total_charges = amr_meter_charges + transmission_charges + system_operation_charges + rkvar_penalty + import_energy_charges + scheduling_charges;

      let m_wheeling_iex_charges = 1.06063;
      let wheeling_iex_charges = m_wheeling_iex_charges * d.sum_iex.total;

      let m_wheeling_solar_charges = 0.5345;
      let wheeling_solar_charges = m_wheeling_solar_charges * (d.sum_solar.total ?? 0);

      let wheeling_total_charges = wheeling_iex_charges + wheeling_solar_charges;

      let adjustment_charges = oa_total_charges + wheeling_total_charges;

      let net_payable = assesment_amount + self_gen_tax + iex_etax + adjustment_charges;

      return {
        demand_sanctioned,
        demand_max,
        demand_charged,
        m_demand_rate,
        demand_total,

        tneb_total,
        tneb_peaktotal,
        tneb_nonpeaktotal,

        m_industrial_con,
        industrial_con,

        m_peakhour_con,
        peakhour_con,

        m_nonpeakhour_con,
        nonpeakhour_con,

        total_energycharges,

        total_energycharges_demand,

        meter_rent,
        m_con_meter_count,
        m_gen_meter_count,
        m_meter_rent,

        cross_subsidy_charges,
        m_cross_subsidy_charges,

        m_add_surcharge,
        add_surcharge,

        m_electricity_tax_perc,
        electricity_tax,

        m_self_gen_tax_perc,
        self_gen_tax,

        m_iex_etax_perc,
        iex_etax,

        adjustment_charges,

        gen_total_mw,
        noofdays,
        m_amr_meter_charges,
        amr_meter_charges,
        m_transmisson_charges,
        transmission_charges,
        m_system_operation_charges,
        system_operation_charges,
        m_rkvar_penalty,
        rkvar_penalty,
        m_import_energy_charges,
        import_energy_charges,
        m_scheduling_charges,
        scheduling_charges,
        oa_total_charges,

        m_wheeling_iex_charges,
        wheeling_iex_charges,
        m_wheeling_solar_charges,
        wheeling_solar_charges,
        wheeling_total_charges,
        assesment_amount,

        net_payable,
      };
    },
    [d]
  );

  let k = useMemo(() => {
    return calc();
  }, [calc]);

  if (!k || !d) return null;

  function row(d, title, value, no, subtitle, isfooter, style) {
    return (
      <tr className={`${isfooter ? "fw-bold" : null}`} style={style}>
        <td style={{ color: "inherit" }}>{no}</td>
        <td style={{ color: "inherit" }}>
          {title}
          <br />
          {subtitle && <small className={isfooter ? "fw-normal" : null}>{subtitle}</small>}
        </td>
        <td style={{ color: "inherit" }} className="text-end">
          {value}
        </td>
      </tr>
    );
  }

  return (
    <>
      <Container className="text-light py-4 text-center">
        <h5 className="text-secondary">EB Bill Summary Detail</h5>
        <h1>{d.data.name}</h1>
        <h5 className="text-secondary">
          {state.startdt && moment(state.startdt).format("ll")} to {state.enddt && moment(state.enddt).format("ll")}
        </h5>
      </Container>
      <Table size="sm" bordered>
        <tbody>
          {row(d, "Consumption Peak", f_num(d.sum.peak), 1.1)}
          {row(d, "Consumption Non Peak", f_num(d.sum.nonpeak), 1.2)}
          {row(d, "Consumption Total", f_num(d.sum.total), 1, null, true)}

          <br />
          {row(d, "IEX Peak", f_num(d.sum_iex.peak), 2.1)}
          {row(d, "IEX Non Peak", f_num(d.sum_iex.nonpeak), 2.2)}
          {row(d, "IEX Total", f_num(d.sum_iex.total), 2, null, true)}

          <br />
          {row(d, "Generation Peak", f_num(d.sum_solar.peak), 3.1)}
          {row(d, "Generation Non Peak", f_num(d.sum_solar.nonpeak), 3.2)}
          {row(d, "Generation Total", f_num(d.sum_solar.total), 3, null, true)}

          <br />
          {/* {row(d, "TNEB Total", f_num(k.tneb_total), 4.1)} */}
          {row(d, "TNEB Peak Total", f_num(k.tneb_peaktotal), 4.2)}
          {row(d, "TNEB Non Peak Total", f_num(k.tneb_nonpeaktotal), 4.3)}
          <br />
          {row(d, "Industrial Charges", f_num(k.industrial_con), 5.1, `${k.m_industrial_con} ₹/unit * ${f_num(k.tneb_total)}`)}
          {row(d, "Peak Hour Charges", f_num(k.peakhour_con), 5.2, `${k.m_peakhour_con} ₹/unit * ${f_num(k.tneb_peaktotal)}`)}
          {row(d, "Non Peak Hour Charges", f_num(k.nonpeakhour_con), 5.3, `${k.m_nonpeakhour_con} ₹/unit * ${f_num(k.tneb_nonpeaktotal)}`)}
          {row(d, "Total Energy Charges", f_num(k.total_energycharges), 5, null, true)}
          <br />
          {row(d, "Sanctioned Demand", f_num(k.demand_sanctioned), 6.1)}
          {row(d, "Max Demand", f_num(k.demand_max), 6.2)}
          {row(d, "Demand Charges", f_num(k.demand_total), 6, `${k.m_demand_rate} ₹/unit * ${f_num(k.demand_charged)}`, true)}
          <br />
          {row(d, "Total Demand & Energy Charges", f_num(k.total_energycharges_demand), 7)}
          <br />
          {row(d, "Meter Rent", f_num(k.meter_rent), 8, `${k.m_meter_rent} ₹/meter * ${f_num(k.m_con_meter_count)} meters`)}
          <br />
          {row(d, "Cross Subsidy Charges (Incl. 18% GST)", f_num(k.cross_subsidy_charges), 9, `${f_num(d.sum_iex.total)} units * ${k.m_cross_subsidy_charges} ₹/unit`)}
          <br />
          {row(d, "Electricity Tax", f_num(k.electricity_tax), 10, `${f_num(k.industrial_con + k.peakhour_con)} units * ${k.m_electricity_tax_perc} %`)}
          <br />
          {row(d, "Additional Surcharges (Incl. 18% GST)", f_num(k.add_surcharge), 11, `${f_num(d.sum_iex.total)} units * ${k.m_add_surcharge} ₹/unit`)}

          <br />
          {row(d, "Total Assessment Amount", f_num(k.assesment_amount), 12, `${f_num(k.assesment_amount)} ₹/meter  * ${k.m_con_meter_count} meters`, true)}
          <br />
          {row(d, "Self Generation Tax", f_num(k.self_gen_tax), 13, `${f_num(d.sum_net.total)} units  * ${k.m_self_gen_tax_perc}%`)}
          {/* Need to Test - AAA */}

          <br />
          {row(d, "IEX E Tax", f_num(k.iex_etax), 14, `${f_num(d.sum_iex.total)} units  * ${k.m_iex_etax_perc}%`)}

          <br />
          {row(d, "AMR Meter Reading Charges", f_num(k.amr_meter_charges), 15.1, `${f_num(k.m_amr_meter_charges)} ₹/meter  * ${k.m_gen_meter_count} meters`)}
          {row(d, "Transmission Charges", f_num(k.transmission_charges), 15.2, `${f_num(k.m_transmisson_charges)} ₹/MW  * ${k.gen_total_mw} MW * ${k.noofdays} days`)}
          {row(d, "System Operation Charges", f_num(k.system_operation_charges), 15.3, `${f_num(k.m_system_operation_charges)} ₹/MW  * ${k.gen_total_mw} MW * ${k.noofdays} days`)}
          {row(d, "RKVAR Penalty", f_num(k.rkvar_penalty), 15.4, `${f_num(k.m_rkvar_penalty)} ₹/MW  * ${k.gen_total_mw} MW`)}
          {row(d, "Import Energy Charges", f_num(k.import_energy_charges), 15.5, `${f_num(k.m_import_energy_charges)} ₹/meter  * ${k.m_gen_meter_count} meters`)}
          {row(d, "Scheduling Charges", f_num(k.scheduling_charges), 15.6, `${f_num(k.m_scheduling_charges)} ₹/day  * ${k.noofdays} days * ${k.m_gen_meter_count} meters`)}
          {row(d, "Total OA Charges", f_num(k.oa_total_charges), 15, null, true)}

          <br />
          {row(d, "IEX Wheeling Charges", f_num(k.wheeling_iex_charges), 16.1, `${k.m_wheeling_iex_charges} ₹/unit  * ${f_num(d.sum_iex.total)} units`)}
          {row(d, "Solar Wheeling Charges", f_num(k.wheeling_solar_charges), 16.2, `${k.m_wheeling_solar_charges} ₹/unit  * ${f_num(d.sum_solar.total)} units`)}
          {/* Need to Test - AAA */}

          {row(d, "Total Wheeling Charges", f_num(k.wheeling_total_charges), 16, null, true)}

          <br />
          {row(d, "Adjustment Charges", f_num(k.adjustment_charges), 17, null, true)}

          <br />
          {row(d, "Net Payable", f_num(k.net_payable), 18, null, true, { color: null })}
          <br />
          {/* {row(d, "Net Payable (Without Solar & IEX)", f_num(k_nongen.net_payable), 18.1, null, true)} */}
          {/* {row(d, "Net Savings", f_num(k_nongen.net_payable - k.net_payable), 18.2, null, true)} */}
        </tbody>
      </Table>
    </>
  );
}
