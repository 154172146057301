import { get_config_vars } from "./serverconfig";
import { array_2d_to_1d } from "./utility";

export const magixFETCH = (url, parameters, controller, options, _timeout) => {
  if (controller === null || typeof controller !== "object") {
    controller = new AbortController();
  }

  function setTimer() {
    let timeout = _timeout ?? 30000;
    return window.setTimeout(() => controller.abort(), timeout);
  }

  let timer = setTimer();

  function clearTimer() {
    if (timer !== null) window.clearTimeout(timer);
  }

  const signal = controller.signal;
  if (!options) {
    options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "max-age=60",
      },
      body: JSON.stringify(parameters),
      signal,
    };
  }

  const start = new Date().getTime();
  let response = fetch(url, options);

  return response
    .then((callback) => {
      clearTimer();
      console.log(`Fetch Execution End ${url} => ${((new Date().getTime() - start) / 1000).toString()} seconds `);
      if (!callback.ok) {
        //Error
        return callback.text().then((text) => {
          throw new Error(text);
        });
      }
      let json = callback.json();
      return json;
    })
    .catch((err) => {
      console.log(err);
      clearTimer();
      throw err;
    });
};

export function fetch_status() {
  return get_config_vars().then((vars) => {
    let p =
      vars.SERVER_URL &&
      vars.SERVER_URL.map((s) => {
        return magixFETCH(s, null, null, { method: "get" }).then((res) => res.clients);
      });
    if (!p) return Promise.reject(false);
    return allSettled(p).then((res) => {
      return array_2d_to_1d(res.map((r) => r.value));
    });
  });
}

export function fetch_machines(typewise) {
  return get_config_vars().then((vars) => {
    let p =
      vars.SERVER_URL &&
      vars.SERVER_URL.map((server) => {
        let m = "api/machines";
        let url = server + m;
        return magixFETCH(url, { typewise }).then((res) => {
          return res.map((data) => {
            return { server, data };
          });
        });
      });
    if (!p) return Promise.reject(false);
    return allSettled(p).then((res) => {
      return array_2d_to_1d(res.map((r) => r.value));
    });
  });
}

export function fetch_summary(server, mc_ids, start, end, peaks, hours, minutes, startend, monthly) {
  let m = "api/summary";
  let url = server + m;
  return magixFETCH(url, { mc_ids, start, end, peaks, hours, minutes, startend, monthly });
}

export function fetch_summary_monthly(server, mc_ids, start, end) {
  let m = "api/summary";
  let url = server + m;
  return magixFETCH(url, { mc_ids, start, end, monthly: true }, null, null, 999999999);
}

export function fetch_iex(start, end, url) {
  if (!url) return Promise.reject(false);
  // let url = "https://sheets.googleapis.com/v4/spreadsheets/1kpQa8fA-X7GXqcB_ZxiisQ1-4GfbOrf8bmRll4LSe6g/values/Sheet1?key=AIzaSyDTc0h-pZGdRY9NzuqL9g7oI3HaM1xixQU";
  let options = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "max-age=60",
    },
  };
  return magixFETCH(url, null, null, options, 5000);
}

export function allSettled(promises) {
  if (Promise.allSettled) {
    return Promise.allSettled(promises);
  }
  let wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (val) => ({ status: "fulfilled", value: val }),
      (err) => ({ status: "rejected", reason: err })
    )
  );
  return Promise.all(wrappedPromises);
}
