import { useQuery } from "react-query";
import { fetch_status } from "../common/fetcher";
import { Container, Table } from "react-bootstrap";

export function Status(props) {
  let query_status = useQuery("fetch_status", () => fetch_status());

  if (query_status.data) {
    console.log(query_status);
  }

  return (
    <Container fluid>
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>S.No</th>
            <th>name</th>
            <th>ip</th>
            <th>lastReading</th>
            <th>last Reading On</th>
            <th>lastdata</th>
            <th>connectionerror</th>
          </tr>
        </thead>
        <tbody>
          {query_status.data &&
            query_status.data.map((d, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{d.name}</td>
                <td>{d.ip}</td>
                <td>{d.lastReading}</td>
                <td>{d.last_updated_on}</td>
                <td>{d.lastdata}</td>
                <td>{d.connectionerror}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}
