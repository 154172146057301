import { Badge, Button, ButtonGroup, Col, Container, Form, Row, Spinner, Stack, Table, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { allSettled, fetch_iex, fetch_machines, fetch_summary, fetch_summary_monthly } from "../common/fetcher";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Area, AreaChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateInputView, GridBoxView, GridBoxViewColumn, LabelBadge, SpinnerView, YearDropdown } from "../components/CommonViews";
import { chunk, f_comma_num, f_num, round_decimals } from "../common/utility";
import { get_config_vars } from "../common/serverconfig";

export function DayMachines(props) {
  const navigate = useNavigate();
  let { state } = useLocation();
  let query = useQuery("fetch_machines", () => fetch_machines(), {
    onSuccess: (data) => {
      if (data && data.length === 1) navigate("machine", { state: { data: data[0] } });
    },
  });

  if (query.isLoading) return <SpinnerView />;
  else if (query.error) return "Error";

  return (
    <Container fluid className="my-5">
      <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
        {query.data &&
          query.data.map((d, i) => {
            if (d && d.data && d.data.name) {
              return (
                <Link to={`machine`} state={{ data: d }}>
                  <Button key={`DayMachines_${i}`} variant={`${state && state.data && state.data.data._id === d.data._id ? "light" : "outline-light"}`} className="p-3">
                    {d.data.name}
                  </Button>
                </Link>
              );
            }
            return null;
          })}
      </Stack>
      <Outlet />
    </Container>
  );
}

export const ReportContext = createContext();

export function ReportProvider(props) {
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();

  const [selected_mc, setselected_mc] = useState([]);

  const [mcs, setmcs] = useState();

  let query2 = useQuery("config_vars", get_config_vars);
  let color_accent = useMemo(() => (query2.data && query2.data.COMPANY_COLOR) ?? "#138AF2", [query2.data]);

  useEffect(() => {
    if (selected_mc.length) sessionStorage.setItem("selected_mc", JSON.stringify(selected_mc));
  }, [selected_mc]);

  useEffect(() => {
    if (startdate) sessionStorage.setItem("startdate", startdate.toISOString());
  }, [startdate]);

  useEffect(() => {
    if (enddate) sessionStorage.setItem("enddate", enddate.toISOString());
  }, [enddate]);

  useEffect(() => {
    let d = sessionStorage.getItem("selected_mc");
    if (d) setselected_mc(JSON.parse(d));

    let s = sessionStorage.getItem("startdate");
    setstartdate(s ? moment(s) : moment().startOf("month"));

    let e = sessionStorage.getItem("enddate");
    setenddate(e ? moment(e) : moment());
  }, []);

  let query_machines = useQuery("fetch_machines", () => fetch_machines(), {
    onSuccess: (data) => {
      if (selected_mc.length === 0 && data && Array.isArray(data)) {
        let temp = data.map((d) => d && d.data && d.data._id);
        setselected_mc([...temp]);
      }
    },
  });

  let query_summary = useQuery(
    ["fetch_summary", startdate, enddate, selected_mc],
    () => {
      function execute(type) {
        let obj = {};
        let arr = [];
        selected_mc.forEach((m) => {
          let d = query_machines.data.find((f) => f && f.data && f.data._id === m);
          if (typeof d === "object" && d.data && d.data.type === type) {
            if (!obj[d.server]) obj[d.server] = [];
            obj[d.server].push(d.data._id);
            arr.push(d);
          }
        });

        // query_machines.data.forEach((d) => {
        //   if (typeof d === "object" && d.data && d.data.type === type) {
        //     if (!obj[d.server]) obj[d.server] = [];
        //     obj[d.server].push(d.data._id);
        //   }
        // });

        let promises = [];
        for (const [k, v] of Object.entries(obj)) {
          promises.push(fetch_summary(k, v, startdate, enddate, true, null, null, null, null));
        }

        let promises_final = allSettled(promises).then((res) => {
          return res.map((r) => r.value);
        });

        return { promises_final, arr };
      }

      let gens = execute("gen");
      let cons = execute("con");

      setmcs(() => {
        return { gen: gens.arr, con: cons.arr };
      });
      return Promise.all([gens.promises_final, cons.promises_final]);
    },
    { enabled: typeof query_machines.data === "object" ? true : false }
  );

  let query_config = useQuery("config_vars", get_config_vars);

  let query_iex = useQuery(
    ["fetch_iex", query_machines.data, selected_mc],
    () => {
      return QueryIEX(query_machines.data, selected_mc, startdate, enddate, true);
    },
    { enabled: typeof query_config.data === "object" && query_config.data.SUMMARY_IEX === "1" && Array.isArray(query_machines.data) }
  );

  function run(summary, _iex) {
    let rows = [];

    function execute(obj, key) {
      obj.forEach((o) => {
        if (o) {
          o.forEach((e) => {
            if (e.length) {
              // This code will break with there is same type(con/gen) from 2 different servers. Need to be fixed. AAA - 4th Oct
              let d = e[0];
              if (d) {
                let found = rows.findIndex((p) => (p && d && p.date && d.date ? p.date === d.date : false));
                if (found === -1) {
                  rows.push({ date: d.date });
                  found = rows.length - 1;
                }
                rows[found][key] = d;
              }
            }
          });
        }
      });
    }

    execute(summary[0], "gen");
    execute(summary[1], "con");

    //IEX Data

    if (_iex && Array.isArray(_iex)) {
      _iex.forEach((d) => {
        if (d.date) {
          let found = rows.findIndex((p) => {
            let p_date = p && p.date && new moment(p.date);
            if (!p_date) return false;
            return d.date.isSame(p_date, "date");
          });
          if (found > -1) {
            rows[found].iex = d;
          } else {
            console.error("query_iex.data date is missing in rows");
          }
        }
      });
    }

    //find the Balance
    let sum = {
      gen: { mpeak: 0, epeak: 0, nonpeak: 0, total: 0, mnonpeak: 0, enonpeak: 0 },
      gen_yield: { mpeak: 0, epeak: 0, nonpeak: 0, mnonpeak: 0, enonpeak: 0 },
      con: { mpeak: 0, epeak: 0, nonpeak: 0, total: 0, mnonpeak: 0, enonpeak: 0 },
      iex: { mpeak: 0, epeak: 0, nonpeak: 0, mnonpeak: 0, enonpeak: 0 },
      bal: { mpeak: 0, epeak: 0, nonpeak: 0, peak: 0, mnonpeak: 0, enonpeak: 0 },
    };

    rows.forEach((o) => {
      let gen = o.gen;
      let con = o.con;
      let iex = o.iex;

      if (gen && gen.peaks) {
        //Sum
        sum.gen.mpeak += gen.peaks.mpeak;
        sum.gen.epeak += gen.peaks.epeak;
        sum.gen.nonpeak += gen.peaks.nonpeak;
        sum.gen.total += gen.peaks.total;
        sum.gen.mnonpeak += gen.peaks.mnonpeak;
        sum.gen.enonpeak += gen.peaks.enonpeak;

        sum.gen_yield.mpeak += gen.peaks.mpeak_yield ?? 0;
        sum.gen_yield.epeak += gen.peaks.epeak_yield ?? 0;
        sum.gen_yield.nonpeak += gen.peaks.nonpeak_yield ?? 0;
        sum.gen_yield.total = (sum.gen_yield.total ?? 0) + (gen.peaks.mpeak_yield ?? 0) + (gen.peaks.epeak_yield ?? 0) + (gen.peaks.nonpeak_yield ?? 0) ?? 0;
        sum.gen_yield.mnonpeak += gen.peaks.mnonpeak_yield ?? 0;
        sum.gen_yield.enonpeak += gen.peaks.enonpeak_yield ?? 0;

        let _maxdemand = gen.peaks.maxdemand && gen.peaks.maxdemand.data && gen.peaks.maxdemand.data.power;
        sum.gen.maxdemand = Math.max(sum.gen.maxdemand ?? 0, _maxdemand ?? 0);
      }

      if (con && con.peaks) {
        sum.con.mpeak += con.peaks.mpeak;
        sum.con.epeak += con.peaks.epeak;
        sum.con.nonpeak += con.peaks.nonpeak;
        sum.con.total += con.peaks.total;
        sum.con.mnonpeak += con.peaks.mnonpeak;
        sum.con.enonpeak += con.peaks.enonpeak;

        let _maxdemand = con.peaks.maxdemand && con.peaks.maxdemand.data && con.peaks.maxdemand.data.power;
        sum.con.maxdemand = Math.max(sum.con.maxdemand ?? 0, _maxdemand ?? 0);
      }

      if (iex) {
        sum.iex.mpeak += iex.mpeak;
        sum.iex.epeak += iex.epeak;
        sum.iex.nonpeak += iex.nonpeak;
        sum.iex.total = (sum.iex.total ?? 0) + (iex.mpeak ?? 0) + (iex.epeak ?? 0) + (iex.nonpeak ?? 0);
        sum.iex.mnonpeak += iex.mnonpeak;
        sum.iex.enonpeak += iex.enonpeak;
      }

      if (gen && con && gen.peaks && con.peaks) {
        let bal_mpeak = (gen.peaks.mpeak_yield ?? 0) - (con.peaks.mpeak ?? 0);
        let bal_epeak = (gen.peaks.epeak_yield ?? 0) - (con.peaks.epeak ?? 0);
        let bal_nonpeak = (gen.peaks.nonpeak_yield ?? 0) - (con.peaks.nonpeak ?? 0);
        let bal_peak = (gen.peaks.mpeak_yield ?? 0) + (gen.peaks.epeak_yield ?? 0) - (con.peaks.mpeak ?? 0) - (con.peaks.epeak ?? 0);

        let bal_mnonpeak = (gen.peaks.mnonpeak_yield ?? 0) - (con.peaks.mnonpeak ?? 0);
        let bal_enonpeak = (gen.peaks.enonpeak_yield ?? 0) - (con.peaks.enonpeak ?? 0);

        if (iex) {
          bal_mpeak += iex.mpeak;
          bal_epeak += iex.epeak;
          bal_nonpeak += iex.nonpeak;
          bal_peak = bal_peak + iex.mpeak + iex.epeak;
          bal_mnonpeak += iex.mnonpeak;
          bal_enonpeak += iex.enonpeak;
        }

        o.bal = { date: o.date, peaks: { mpeak: bal_mpeak, epeak: bal_epeak, nonpeak: bal_nonpeak, peak: bal_peak, mnonpeak: bal_mnonpeak, enonpeak: bal_enonpeak } };

        sum.bal.mpeak += bal_mpeak;
        sum.bal.epeak += bal_epeak;
        sum.bal.nonpeak += bal_nonpeak;
        sum.bal.peak = sum.bal.peak + bal_mpeak + bal_epeak;
        sum.bal.mnonpeak += bal_mnonpeak;
        sum.bal.enonpeak += bal_enonpeak;
      }
    });

    return { rows, sum };
  }

  const table_data = useMemo(() => {
    if (!query_summary.data) return false;
    // if (!query_iex.data) return false;
    let v = run(query_summary.data, query_iex.data);
    return v;
  }, [query_summary.data, query_iex.data]);

  const datepicker_ui = useMemo(() => {
    return (
      <Stack gap={3} direction="horizontal" className="mb-4 justify-content-center">
        <DateInputView title="From Date" selected={startdate && startdate.toDate()} onChange={(date) => setstartdate(moment(date))} />
        <DateInputView title="To Date" selected={enddate && enddate.toDate()} onChange={(date) => setenddate(moment(date).endOf("day"))} />
      </Stack>
    );
  }, [enddate, startdate]);

  const onClick = useCallback(
    (d) => {
      document.activeElement.blur();
      let idx = selected_mc.indexOf(d.data._id);
      if (idx > -1) {
        selected_mc.splice(idx, 1);
      } else selected_mc.push(d.data._id);
      setselected_mc([...selected_mc]);
    },
    [selected_mc, setselected_mc]
  );

  const machinelist_ui = useMemo(() => {
    return (
      <Container fluid className="my-5">
        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
          {query_machines.data &&
            query_machines.data.map((d, i) => {
              if (d && d.data && d.data.name) {
                return (
                  <Button key={`DayMachines_${i}`} className="p-3" variant={selected_mc.indexOf(d.data._id) > -1 ? "light" : "outline-light"} onClick={() => onClick(d)}>
                    {d.data.name}
                    <Form.Check type={"checkbox"} checked={selected_mc.indexOf(d.data._id) > -1} disabled />
                  </Button>
                );
              }
              return null;
            })}
        </Stack>
      </Container>
    );
  }, [onClick, query_machines.data, selected_mc]);

  return (
    <ReportContext.Provider value={{ datepicker_ui, table_data, query_config, query_machines, query_summary, startdate, enddate, color_accent, mcs, setselected_mc, selected_mc, run }}>
      <Container fluid>
        {datepicker_ui}
        {machinelist_ui}
        {query_machines.isLoading || query_summary.isLoading ? <SpinnerView /> : query_machines.error || query_summary.error ? "ERROR" : <Outlet />}
      </Container>
    </ReportContext.Provider>
  );
}

export function DayReport(props) {
  let { state } = useLocation();
  const [date, setdate] = useState(new Date());
  let enabled = state !== null && typeof state.data === "object" ? true : false;
  let query = useQuery(["fetch_summary", enabled && state.data.data._id, date], () => fetch_summary(enabled && state.data.server, [enabled && state.data.data._id], date, null, true, true, true, true), { enabled });

  let query2 = useQuery("config_vars", get_config_vars);
  let color_accent = useMemo(() => (query2.data && query2.data.COMPANY_COLOR) ?? "#138AF2", [query2.data]);

  let istoday = useMemo(() => {
    if (state !== null && typeof state.data === "object" && date) {
      let m = new moment(date);
      var istoday = m.isSame(new Date(), "day");
      return istoday;
    }
    return false;
  }, [date, state]);

  let query_otherday = useQuery(
    ["fetch_summary_otherday", enabled && state.data.data._id, date],
    () => {
      if (date) {
        let m = new moment(date);
        var istoday = m.isSame(new Date(), "day");
        let previous_date = istoday ? new moment(date).subtract(1, "day").toDate() : new Date();
        return fetch_summary(state.data.server, [state.data.data._id], previous_date, null, true).then((res) => {
          let d = res && res.length && res[0].length && res[0][0];
          return d;
        });
      }
      return null;
    },
    { enabled }
  );

  useEffect(() => {
    if (date) {
      let date1 = new moment(date);
      if (date1.isSame(new Date(), "day")) {
        let date2 = new moment();
        date2.set({ hour: 6, minute: 59, second: 29, millisecond: 99 });
        if (date1.isBefore(date2)) {
          setdate(date1.subtract(1, "day").toDate());
        }
      }
    }
  }, []);

  let summary = useMemo(() => {
    let data = query.data && query.data.length && query.data[0] && query.data[0].length && query.data[0][0];
    if (data) {
      if (data.minutes) {
        data.minutes.forEach((d) => {
          d.timestamp = new Date(d.created_on).valueOf();
          if (d.data) {
            if (d.data.power && d.data.power < 100000) d.yvalue = d.data.power;
            let active_panels = 0;
            let keys = [1, 2, 3];
            var i = 0;
            keys.forEach((k) => {
              let p = d.data[`panel_inv_${k}`];
              if (p) {
                i++;
                p.forEach((v) => {
                  if (Array.isArray(v)) {
                    v.forEach((e) => {
                      if (e.a) active_panels++;
                    });
                  }
                });
              }
            });
            if (i === keys.length) {
              d.active_panels = active_panels;
            }
          }
        });
      }

      if (data.peaks && data.peaks.maxdemand) {
        let t = new Date(data.peaks.maxdemand.created_on).valueOf();
        data.peaks.maxdemand.timestamp = t;
      }
    }

    return data;
  }, [query.data]);

  if (query.isLoading) return <SpinnerView />;
  else if (query.error) return "Error";

  return (
    <Container fluid>
      <Outlet />
      <Container fluid className="text-center w-100 py-5">
        {/* <DatePicker dateFormat="MMMM do yyyy" selected={date} onChange={(date) => setdate(date)} customInput={<CustomDatePickerInput />} /> */}
        <DateInputView title="Date" selected={date} onChange={(date) => setdate(date)} />
      </Container>
      {summary && (
        <>
          <Row className="pb-5">
            <Col>
              <Stack className="text-center text-white">
                <h1 className="display-2 fw-bold text-shadow bg-light rounded text-dark mx-auto px-5 py-3 my-4">
                  <h5>{state.data.data.type === "con" ? "Consumption" : "Generation"}</h5>
                  {summary.peaks.total ? Math.round(summary.peaks.total) + " units" : null}
                </h1>
                {query_otherday.data && query_otherday.data.peaks && <h6>{(istoday ? `Yesterday` : `Today`) + ` Until ${new moment().format("h:mma")} : ` + (istoday ? Math.round(query_otherday.data.peaks.untilnow) : Math.round(query_otherday.data.peaks.total))}</h6>}
                {!istoday && summary.peaks && <h6>{`Until ${new moment().format("h:mma")} : ` + Math.round(summary.peaks.untilnow)}</h6>}
              </Stack>
            </Col>
          </Row>

          {summary.minutes && (
            <Row className={"w-100"} style={{ height: 550 }}>
              <ResponsiveContainer width={"98%"}>
                <AreaChart data={summary.minutes} margin={{ bottom: 50, top: 50, left: 10, right: 10 }}>
                  <defs>
                    <linearGradient id={"gradient1"} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="20%" stopColor={color_accent} stopOpacity={1} />
                      <stop offset="95%" stopColor={color_accent} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey={"timestamp"} tickFormatter={(d) => moment(d).format("h:mma")} />
                  <YAxis allowDataOverflow={true} allowDecimals={false} tickCount={6} interval={"preserveStartEnd"} tickSize={5} tickFormatter={(v) => Math.round(v)} />
                  <Area type="monotone" dataKey="yvalue" stroke={color_accent} dot={false} activeDot={{ r: 8 }} strokeWidth={4} isAnimationActive={true} fill={`url(#gradient1)`} />

                  <Tooltip
                    content={(o) => {
                      const { payload, label } = o;
                      if (!payload || payload.length === 0 || !label) return null;
                      let d = payload[0].payload;
                      return (
                        <div className="bg-body p-2 rounded">
                          {label && <h6>{moment(label).format("h:mm a")}</h6>}
                          <h6 className="fw-bolder">{Math.round(d.yvalue)} units</h6>
                          {d.active_panels ? <h6 className="fw-bolder">{Math.round(d.active_panels)} active panels</h6> : null}
                        </div>
                      );
                    }}
                  />
                  {summary.peaks && summary.peaks.maxdemand && summary.peaks.maxdemand.timestamp && <ReferenceLine x={summary.peaks.maxdemand.timestamp} stroke={color_accent} label={{ position: "top", value: `Max Demand ${summary.peaks.maxdemand.data && f_num(summary.peaks.maxdemand.data.power)} @ ${new moment(summary.peaks.maxdemand.timestamp).format("h:mm a")}`, fontSize: 16, fontWeight: "bold" }} />}
                </AreaChart>
              </ResponsiveContainer>
            </Row>
          )}

          {summary.startend && (
            <>
              {summary.startend.powerstarttime && summary.startend.powerendtime && (
                <Stack direction="horizontal" className="pb-5">
                  <GridBoxViewColumn k={"Start Time"} v={new moment(summary.startend.powerstarttime.created_on).format("h:mm a")} />
                  <GridBoxViewColumn k={"End Time"} v={new moment(summary.startend.powerendtime.created_on).format("h:mm a")} />
                </Stack>
              )}
              <Stack direction="horizontal" className="pb-5">
                <GridBoxViewColumn k={"M Peak"} v={summary.peaks.mpeak} />
                <GridBoxViewColumn k={"E Peak"} v={summary.peaks.epeak} />
                <GridBoxViewColumn k={"Non Peak"} v={summary.peaks.nonpeak} />
                <GridBoxViewColumn k={"M Non Peak"} v={summary.peaks.mnonpeak} />
                <GridBoxViewColumn k={"E Non Peak"} v={summary.peaks.enonpeak} />
              </Stack>
            </>
          )}

          {summary.hours && <GridBoxView data={summary.hours} keyfield={(h, i) => new moment(h.start).format("ha")} valuefield={(h, i) => h.value} />}
        </>
      )}
    </Container>
  );
}

export function SummaryReport(props) {
  let ctx = useContext(ReportContext);

  let show_iex = ctx.query_config.data && ctx.query_config.data.SUMMARY_IEX === "1";
  let show_con = ctx.query_config.data && ctx.query_config.data.SUMMARY_CONSUMPTION === "1";

  let view2 = true;
  return (
    ctx.table_data && (
      <>
        <Table striped bordered hover responsive size="sm" className={`mx-auto text-center border-dark`}>
          {/* <colgroup>
            <col colSpan={7} />
            <col colSpan={3} style={{ visibility: () === "1" ? "visible" : "collapse" }} />
            <col colSpan={5} style={{ visibility: ( === "1" ? "visible" : "collapse" }} />
          </colgroup> */}
          <thead>
            <tr>
              <th>Date</th>
              <th colSpan={view2 ? 4 : 6}>Generation</th>
              <th colSpan={view2 ? 3 : 5}>Generation Yield {ctx.table_data.length && `(${ctx.table_data[0].gen.lossperc})`}</th>
              {show_iex && <th colSpan={view2 ? 3 : 5}>IEX</th>}
              {show_con && <th colSpan={view2 ? 4 : 6}>Consumption</th>}
              {show_con && <th colSpan={view2 ? 2 : 4}>Balance</th>}
            </tr>
            <tr className={`${view2 && `dayreport_view2`}`}>
              <th></th>
              <th>Max Demand</th>
              <th>M Peak</th>
              <th>E Peak</th>
              <th>Non Peak</th>
              <th>M Non Peak</th>
              <th>E Non Peak</th>

              <th>M Peak</th>
              <th>E Peak</th>
              <th>Non Peak</th>
              <th>M Non Peak</th>
              <th>E Non Peak</th>

              {show_iex && (
                <>
                  <th>M Peak</th>
                  <th>E Peak</th>
                  <th>Non Peak</th>
                  <th>M Non Peak</th>
                  <th>E Non Peak</th>
                </>
              )}

              {show_con && (
                <>
                  <>
                    <th>Max Demand</th>
                    <th>M Peak</th>
                    <th>E Peak</th>
                    <th>Non Peak</th>
                    <th>M Non Peak</th>
                    <th>E Non Peak</th>
                  </>
                  <>
                    <th>Peak</th>
                    <th>Non Peak</th>
                    <th>M Non Peak</th>
                    <th>E Non Peak</th>
                  </>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {ctx.table_data &&
              ctx.table_data.rows.map((d) => (
                <tr className={`text-end ${view2 && `dayreport_view2`}`}>
                  <td className="text-center">{d.date ? new moment(d.date).format("D MMM") : null}</td>
                  <td>{d.gen && d.gen.peaks && d.gen.peaks.maxdemand && d.gen.peaks.maxdemand.data && d.gen.peaks.maxdemand.data.power && f_num(d.gen.peaks.maxdemand.data.power)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.epeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.nonpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mnonpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.enonpeak)}</td>

                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.epeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.nonpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mnonpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.enonpeak_yield)}</td>

                  {show_iex && (
                    <>
                      <td>{f_num(d.iex && d.iex.mpeak)}</td>
                      <td>{f_num(d.iex && d.iex.epeak)}</td>
                      <td>{f_num(d.iex && d.iex.nonpeak)}</td>
                      <td>{f_num(d.iex && d.iex.mnonpeak) ?? "-"}</td>
                      <td>{f_num(d.iex && d.iex.enonpeak) ?? "-"}</td>
                    </>
                  )}

                  {show_con && (
                    <>
                      <td>{d.con && d.con.peaks && d.con.peaks.maxdemand && d.con.peaks.maxdemand.data && d.con.peaks.maxdemand.data.power && f_num(d.con.peaks.maxdemand.data.power)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.mpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.epeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.nonpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.mnonpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.enonpeak)}</td>

                      {/* <td className={`${d.bal && d.bal.peaks && d.bal.peaks.mpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.mpeak)}</td>
                  <td className={`${d.bal && d.bal.peaks && d.bal.peaks.epeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.epeak)}</td> */}
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.peak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.peak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.nonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.nonpeak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.mnonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.mnonpeak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.enonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.enonpeak)}</td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
          <tfoot>
            {ctx.table_data && ctx.table_data.sum && (
              <tr className={`text-end fw-bold ${view2 && `dayreport_view2`}`}>
                <td></td>
                <td>{round_decimals(ctx.table_data.sum.gen.maxdemand)}</td>

                <td>{f_num(ctx.table_data.sum.gen.mpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.epeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.nonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.mnonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.enonpeak)}</td>

                <td>{f_num(ctx.table_data.sum.gen_yield.mpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.epeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.nonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.mnonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.enonpeak)}</td>

                {show_iex && (
                  <>
                    <td>{f_num(ctx.table_data.sum.iex.mpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.epeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.nonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.mnonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.enonpeak)}</td>
                  </>
                )}

                {show_con && (
                  <>
                    <td>{round_decimals(ctx.table_data.sum.con.maxdemand)}</td>
                    <td>{f_num(ctx.table_data.sum.con.mpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.epeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.nonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.mnonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.enonpeak)}</td>
                    {/* <td className={`${table_data.sum.bal.mpeak < 0 ? "text-danger" : ""}`}>{f_num(table_data.sum.bal.mpeak)}</td> */}
                    {/* <td className={`${table_data.sum.bal.epeak < 0 ? "text-danger" : ""}`}>{f_num(table_data.sum.bal.epeak)}</td> */}
                    <td className={`${ctx.table_data.sum.bal.peak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.peak)}</td>
                    <td className={`${ctx.table_data.sum.bal.nonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.nonpeak)}</td>
                    <td className={`${ctx.table_data.sum.bal.mnonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.mnonpeak)}</td>
                    <td className={`${ctx.table_data.sum.bal.enonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.enonpeak)}</td>
                  </>
                )}
              </tr>
            )}
          </tfoot>
        </Table>
        <Outlet />
      </>
    )
  );
}

export function MonthReport(props) {
  let { state } = useLocation();
  const [year, setyear] = useState(moment().year());
  let enabled = state !== null && typeof state.data === "object" ? true : false;
  let query = useQuery(["fetch_summary_month", state && state.data && state.data.data._id, year], () => fetch_summary_monthly(state.data.server, [state.data.data._id], moment(`1-1-${year}`, "MM-DD-YYYY").startOf("year").toDate(), moment(`1-1-${year}`, "MM-DD-YYYY").endOf("year").toDate()), { enabled });

  // Consumption - MPeak , EPeak , Non Peak
  //Generation - MPeak , EPeak , Non Peak
  //Generation Yield - MPeak , EPeak , Non Peak
  // IEX - MPeak - EPeak , Non Peak

  let data = query.data;
  return (
    <Container fluid>
      <Outlet />
      <Container fluid className="text-center w-100 py-5">
        <YearDropdown selected={year} onChange={(year) => setyear(year)} />
        {query.isLoading ? (
          <SpinnerView />
        ) : query.error ? (
          "ERROR"
        ) : (
          data && (
            <>
              <Table striped bordered hover responsive size="sm">
                <thead className="text-center">
                  <tr>
                    <th>Date</th>
                    {/* <th colSpan={1}>M Peak</th>
                    <th colSpan={1}>E Peak</th>
                    <th colSpan={1}>Non Peak</th> */}
                    <th colSpan={1}>Total_Yield</th>
                    <th colSpan={1}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((v) => {
                      if (v.length) {
                        let d = v[0];
                        if (d && d.date) {
                          return (
                            <tr className="text-end">
                              <td className="text-center">{d.date ? new moment(d.date).format("MMM YYYY") : null}</td>
                              {/* <td>{f_num(d.peaks && d.peaks.mpeak)}</td>
                          <td>{f_num(d.peaks && d.peaks.epeak)}</td>
                          <td>{f_num(d.peaks && d.peaks.nonpeak)}</td> */}
                              <td>{f_num(d.peaks && d.peaks.total_yield)}</td>
                              <td>{f_num(d.peaks && d.peaks.total)}</td>
                            </tr>
                          );
                        }
                      }
                      return <tr />;
                    })}
                </tbody>
              </Table>
              <Outlet />
            </>
          )
        )}
      </Container>
    </Container>
  );
}

export function CalendarReport(props) {
  let ctx = useContext(ReportContext);

  let data_startdate = useMemo(() => ctx.table_data && ctx.table_data.rows.length && ctx.table_data.rows[0].date && moment(ctx.table_data.rows[0].date), [ctx.table_data]);
  let data_enddate = useMemo(() => ctx.table_data && ctx.table_data.rows.length && ctx.table_data.rows[ctx.table_data.rows.length - 1].date && moment(ctx.table_data.rows[ctx.table_data.rows.length - 1].date), [ctx.table_data]);

  let body_ui = useMemo(() => {
    let temp = [...ctx.table_data.rows];

    // Start Add Dummy
    let _s = [];
    if (data_startdate) {
      let startdate_idx = data_startdate.isoWeekday();
      for (var i = 7 - startdate_idx + 1; i < 7; i++) {
        _s.push({
          date: moment(data_startdate)
            .subtract(i - startdate_idx + 1, "days")
            .toISOString(),
        });
      }
      temp = _s.reverse().concat(temp);
    }

    //End Add Dummy
    if (data_enddate) {
      let enddate_idx = data_enddate.isoWeekday();
      for (var h = enddate_idx + 1; h <= 7; h++) {
        temp.push({
          date: moment(data_enddate)
            .add(h - enddate_idx, "days")
            .toISOString(),
        });
      }
    }

    let c = chunk(temp, 7);
    let arr = [];

    let dow_sum = Array.from({ length: 7 }, () => ({ gen: 0, con: 0 }));

    for (var j = 0; j < c.length; j++) {
      let tr = [];

      let week_sum = { gen: 0, con: 0 };

      for (var k = 0; k < c[j].length; k++) {
        let d = c[j][k];

        let dow = moment(d.date).isoWeekday();
        //DOW & Weekly Sum
        if (d.gen && d.gen.peaks && d.gen.peaks.total) {
          let v = d.gen.peaks.total;
          week_sum.gen += v;
          dow_sum[dow - 1].gen += v;
        }

        if (d.con && d.con.peaks && d.con.peaks.total) {
          let v = d.con.peaks.total;
          week_sum.con += v;
          dow_sum[dow - 1].con += v;
        }

        let td = (
          <td style={{ height: "90px" }} className="p-2 text-center">
            <h6>{moment(d.date).format("D MMM")}</h6>
            <Stack gap={1}>
              {d.gen && <LabelBadge title={"GEN"} value={d.gen.peaks.total} />}
              {d.con && <LabelBadge title={"CON"} value={d.con.peaks.total} />}
            </Stack>
          </td>
        );
        tr.push(td);
      }

      //Week SUM TR
      tr.push(
        <td style={{ height: "90px", borderLeftWidth: 5 }} className="p-2 text-center">
          <h6 style={{ color: ctx.color_accent ?? "inherit" }}>Week Total</h6>
          <Stack gap={1}>
            {week_sum.gen > 0 && <LabelBadge title={"GEN"} value={week_sum.gen} />}
            {week_sum.con > 0 && <LabelBadge title={"CON"} value={week_sum.con} />}
          </Stack>
        </td>
      );
      arr.push(<tr>{tr}</tr>);
    }

    //DOW SUM TR
    dow_sum.push({ gen: ctx.table_data.sum.gen.total, con: ctx.table_data.sum.con.total }); // Total of Date Range

    let foot = dow_sum.map((o) => {
      return (
        <td style={{ height: "90px", borderTopWidth: 5 }} className="p-2 text-center">
          <h6 style={{ color: ctx.color_accent ?? "inherit" }}>Total</h6>
          <Stack gap={1}>
            {o.gen > 0 && <LabelBadge title={"GEN"} value={o.gen} />}
            {o.con > 0 && <LabelBadge title={"CON"} value={o.con} />}
          </Stack>
        </td>
      );
    });

    arr.push(<tr>{foot}</tr>);

    return arr;
  }, [ctx.color_accent, ctx.table_data.rows, data_enddate, data_startdate]);

  return (
    <>
      <Table striped bordered hover responsive size="sm" className="mx-auto text-light">
        <thead>
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Week Total"].map((d) => (
            <th className="text-center">{d}</th>
          ))}
        </thead>
        <tbody>{body_ui}</tbody>
      </Table>
    </>
  );
}

export function QueryIEX(query_machines, selected_mc, startdate, enddate, merge) {
  let promises = [];
  if (!startdate || !enddate) {
    return Promise.reject(false);
  }
  selected_mc.forEach((m) => {
    let d = query_machines.find((f) => f && f.data && f.data._id === m);
    if (typeof d === "object" && d.data && d.data.iexurl) {
      let f = fetch_iex(startdate.toDate(), enddate.toDate(), d.data.iexurl).then((data) => {
        if (data && Array.isArray(data.values)) {
          let rows = [];
          data.values.forEach((d, i) => {
            if (i > 0 && d && d.length > 1) {
              let d_date = new moment(d[0]);
              d_date.set({ hour: 6, minute: 0, second: 0 });
              if (d_date.isBetween(startdate, enddate)) {
                let k = { date: d_date, mpeak: d.length > 1 ? f_comma_num(d[1], true) : 0, epeak: d.length > 2 ? f_comma_num(d[2], true) : 0, nonpeak: d.length > 3 ? f_comma_num(d[3], true) : null, mnonpeak: d.length > 4 ? f_comma_num(d[4], true) : null, enonpeak: d.length > 5 ? f_comma_num(d[5], true) : null };
                rows.push(k);
              }
            }
          });

          return rows;
        }
        return false;
      });
      promises.push(f);
    } else promises.push(false);
  });

  return Promise.all(promises).then((res) => {
    if (merge) {
      let rows = [];
      res.forEach((d) => {
        d &&
          d.forEach &&
          d.forEach((w) => {
            let found = rows.findIndex((r) => {
              if (!w.date) return false;
              return w.date.isSame(r.date, "date");
            });
            if (found > -1) {
              for (const [k, v] of Object.entries(w)) {
                if (typeof v === "number") {
                  rows[found][k] += v;
                }
              }
            } else {
              rows.push(w);
            }
          });
      });
      return rows;
    } else {
      return res;
    }
  });
}
