export function array_2d_to_1d(arr) {
  return [].concat(...arr);
}

export const f_num = (n) => (n ? Math.round(n).toLocaleString("en-IN") : 0);

export const f_comma_num = (n, z) => {
  let k = n && Number(n.replace(/,/g, ""));
  if (!k & z) return 0;
  else return k;
};

export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export const round_decimals = (num, decimal) => Math.round(num * 100) / 100;
